.navbar-wrapper {
  width: 100%;
  margin-left: 0;
  background-color: white;
  border-bottom: solid 1px #f4efe3;
  @media (min-width: 992px) {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  .navbar-nav {
    flex-direction: row;
    margin-right: 24px;
    .nav-link {
      padding: 0.5rem 1rem;
    }
  }
  .navbar-brand {
    padding-left: 1.2rem;
  }
  .profile-img,
  .profile-img img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    object-fit: cover;
  }
}
