@font-face {
  font-family: "brown-bd";
  src: url("./assets/fonts/BrownBold/BrownBold.ttf");
}

@font-face {
  font-family: "brown-md";
  src: url("./assets/fonts/BrownBold/BrownBoldRegular.ttf");
}
@font-face {
  font-family: "brown-rg";
  src: url("./assets/fonts/BrownBold/brown-regular.otf");
}
@import "./button.scss";

body {
  padding: 0;
  margin: 0;
  font-family: "brown-rg", sans-serif;
  font-weight: 400;
  color: #2d261d;
}
html {
  scroll-behavior: smooth;
}
.font-bold {
  font-family: "brown-bd", sans-serif;
  font-weight: 700;
}
.font-medium {
  font-family: "brown-md", sans-serif;
  font-weight: 700;
}
.font18 {
  font-size: 18px !important;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 14px !important;
}
.font20 {
  font-size: 20px !important;
}
.font40 {
  font-size: 40px !important;
}
.font25 {
  font-size: 25px !important;
}
.font35 {
  font-size: 35px !important;
}
.line-height-1 {
  line-height: 1;
}
.click {
  cursor: pointer;
}
.bg-black {
  background-color: #121212 !important;
}
.bg-yellow {
  background-color: #ffc001 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-cream {
  background-color: #e5e5e5 !important;
}
.bg-dark-brown {
  background-color: #81735e !important;
}
.bg-grey {
  background-color: #f0f1ed80 !important;
}
.bg-green {
  background-color: #37e85d !important;
}
.bg-red {
  background-color: rgb(255, 0, 64) !important;
}

.text-black {
  color: #121212 !important;
}
.text-dark {
  color: #38352c !important;
}
.text-yellow {
  color: #ffc001 !important;
}
.text-white {
  color: #f4efe3 !important;
}
.text-dark-brown {
  color: #81735e !important;
}
.text-green {
  color: #37e85d !important;
}
.text-red {
  color: rgb(255, 0, 64) !important;
}
.text-grey {
  color: #b7b7b7 !important;
}
.text-blue {
  color: #337ae4 !important;
}
.w-50px {
  width: 50px !important;
}
.w-250px {
  width: 250px !important;
}
.w-200px {
  width: 200px !important;
}
.btn,
.form-control,
a,
span,
button {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
@media (max-width: 992px) {
  .sm-d-none {
    display: none;
  }
}
@media (min-width: 992px) {
  .lg-d-none {
    display: none;
  }
}
.form-control {
  font-size: 14px;
  color: #484848;
  border-radius: 3px;
  background-color: transparent;
  padding: 0.7rem 1rem;

  &::placeholder {
    color: #484848;
    font-size: 13px;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.br-5 {
  border-radius: 5px;
}
.border-red {
  border: 1px solid rgb(255, 0, 64);
}
.box-shadow {
  box-shadow: 0px 2px 20px rgba(16, 112, 183, 0.1),
    0px 2px 4px -2px rgba(16, 112, 183, 0.1);
}
.body-with-nav {
  min-height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  transition: 0.2s ease-in-out;
  padding: 0 1rem;
  background: #f4efe342;
  @media (min-width: 992px) {
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 0 2rem;
  }
}
.dashboard-card {
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(16, 112, 183, 0.1),
    0px 2px 4px -2px rgba(16, 112, 183, 0.1);
  padding: 20px;
  .dash-icon {
    background-color: rgba(1, 1, 5, 0.171);
    color: rgb(1, 1, 5);
    font-size: 20px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
table {
  caption,
  th,
  td {
    color: #4a473f;
    font-size: 14px;
  }
  th {
    border-bottom: 0;
    font-weight: bold;
    font-size: 16px;
    color: #847b63;
    font-family: "brown-bd";
  }
}
.nav-pills {
  background-color: #b196461a;
  border-radius: 27px;
  .nav-link {
    color: #ffc001;
    font-size: 12px;
    &.active {
      font-size: 12px;
      color: #f4efe3;
      background: #38352c;
      border-radius: 20px;
    }
  }
}
.profile-img-sm {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.input-container {
  width: 300px;
  max-width: 100%;
  position: relative;
  .input-icon {
    top: 8px;
    position: absolute;
    left: 10px;
    color: #8b9eb0;
  }
  .form-control {
    background: #fff;
    box-shadow: 0px 2px 10px rgba(169, 181, 192, 0.2);
    padding: 0.375rem 0.75rem 0.375rem 2rem;
    border-radius: 6px;
    border: none;
  }
}
.report-cards {
  .dropdown-item {
    font-size: 12px;
    &:active,
    &:focus {
      background: #ffc0011a !important;
      color: #81735e;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.6em;
    vertical-align: 0.1em;
    content: "";
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;
  }
}
.settings-page {
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.w-10 {
  width: 10%;
}
.w-90 {
  width: 90%;
}
.user-profile-page {
  .banner {
    background-image: linear-gradient(
        to bottom,
        rgba(126, 139, 132, 0.78),
        rgba(237, 250, 243, 0.97)
      ),
      url("./assets/img/pexels-felipe-alves-9070073.jpg");
    height: 24vh;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 15vh;
    @media (max-width: 992px) {
      margin-bottom: -66px;
    }
  }
  .profile {
    position: absolute;
    bottom: -92px;
    left: 38px;
    p {
      line-height: 1.2;
    }
    .profile-img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
    }
    @media (max-width: 992px) {
      position: relative;
      bottom: 0;
      left: 0;
      .profile-img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .totalcard {
    .totalcard-img {
      width: 35px;
    }
    .total-number {
      font-size: 25px;
      line-height: 1;
    }
    .total-label {
      font-size: 14px;
    }
    @media (max-width: 992px) {
      .totalcard-img {
        width: 20px;
      }
      .total-label {
        font-size: 12px;
      }
      .total-number {
        font-size: 18px;
      }
    }
  }
}
.react-skeleton-load {
  width: 100% !important;
}

input:checked {
  cursor: pointer;
}

.key-revoked {
  border: solid 1px rgb(255, 0, 64) !important;
  opacity: 0.5;
}

.key-used {
  border: solid 1px #37e85d !important;
  opacity: 0.5;
}
