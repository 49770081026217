.dashboard-empty-list {
  margin: auto;
  span {
    font-size: 20px;
    color: #666;
    text-align: center;
    background: #f1f1f1;
    padding: 10px 50px;
    margin: 40px 0 40px 0;
    display: inline-block;
    border: 2px solid #e9e9e9;
    border-radius: 5px;
    font-weight: 400;
  }
}
