.login-wrapper {
  background-color: #121212;
  min-height: 100vh;
  background-image: url("../assets/img/patern.png");
  background-size: contain;
  background-position: center;
  padding: 20px;
  @media (max-width: 990px) {
    padding: 20px 0;
  }
  h2 {
    font-weight: 700;
    font-family: "brown-bd";
    font-size: 50px;
    color: #f4efe3;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .cad {
    margin: 20px 0 0 0;
    background-color: #f4efe3;
    box-shadow: 0px 2px 20px rgba(16, 112, 183, 0.1),
      0px 2px 4px -2px rgba(16, 112, 183, 0.1);
    border-radius: 7px;
    min-height: calc(100vh - 60px);
    .cad-header {
      height: 350px;
      background-image: linear-gradient(to bottom, rgba(3, 3, 4, 0.52), #212529),
        url("../assets/img/black-people.png");
      background-repeat: no-repeat;
      background-size: cover;
      color: #f4efe3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .cad-body {
      .title {
        letter-spacing: 1.5px;
      }
    }
  }
}
