.sidebar-contain {
  background: #171e40;
}
.sidebar {
  transition: 0.3s all ease-in-out;
  overflow-y: scroll;
  z-index: 3;
  position: fixed;
  min-height: 100vh;
  width: 0% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 992px) {
    width: 200px !important;
    display: block !important;
  }
}

.menu-icon {
  position: absolute;
  right: 22px;
  top: 9px;
  width: 20px;
  font-size: 28px;
  display: none;
  z-index: 1;
  @media (max-width: 992px) {
    display: block;
  }
}

.closeMenu {
  display: none;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
  font-size: 40px;
  position: absolute;
  right: 0;
}
@media (max-width: 992px) {
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.8);
    &.overlay-isOpen {
      width: 100%;
      z-index: 3;
      .closeMenu {
        display: block;
      }
    }
  }
  .sidebar-isOpen {
    width: 40% !important;
  }
}
@media (max-width: 600px) {
  .sidebar-isOpen {
    width: 80% !important;
  }
}
.sidebar-wrapper {
  padding: 2rem;
  min-height: 100vh;
  position: absolute;
  background-color: #fff;
  border-right: solid 1px #f4efe3;
  background-size: cover;
  background-image: url("../../assets/img/patern.png");
  width: 100%;
  @media (min-width: 992px) {
    padding: 1rem 1rem 2rem 1rem;
  }
  p {
    color: #2d261dcf;
    margin: 0;
  }
  ul {
    padding-left: 0;
    li {
      list-style: none;
      .side-link {
        font-size: 18px;
        padding: 11px;
        margin: 8px 0;
        display: flex;
        color: #a59d8a;
        align-items: center;
        text-decoration: none;
        .side-icon {
          margin-right: 0.5rem !important;
          margin-top: -0.2rem;
        }
        &.active {
          color: #ffc001;
          background-color: #ffc0011a;
          border-radius: 27px;
        }
      }
    }
  }
}
