.btn {
  font-weight: bold;
  font-family: "brown-bd";
}
.btn-grey {
  min-height: 40px;
  min-width: 150px;
  background-color: #e9dfc8;
  color: #434343;
  &:hover {
    color: #434343;
  }
}
.btn-outline-grey {
  min-height: 40px;
  min-width: 150px;
  background-color: transparent;
  border: #e9dfc8 solid 1px;
  color: #7c7564;
  &:hover {
    color: #7c7564;
  }
}
.btn-green {
  min-height: 40px;
  min-width: 150px;
  background-color: #37e85d;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-red {
  min-height: 40px;
  min-width: 150px;
  background-color: #e83746;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-blue {
  min-height: 40px;
  min-width: 150px;
  background-color: #5282a5;
  color: white;
  &:hover {
    color: white;
  }
}
.btn-sm {
  min-height: 20px;
  min-width: 60px;
}
